.wrapper {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-top: 1px solid #f9f9f9;
  display: flex;
  justify-content: flex-end;
  padding: 24px 0;
}

.wrapper > * {
  margin-left: 16px;
}
