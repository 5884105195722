.content {
  margin-top: 16px;
}

.content .card {
  border-radius: 4px;
}

.content .card :global(.ant-card-body) {
  padding-top: 0;
  padding-bottom: 0;
}

.content .card :global(.ant-card-head) {
  border-bottom: 0;
}

.content .card .divider {
  margin-left: -24px;
  margin-right: -24px;
  background-color: #f0f0f0;
}

.form {
  margin-top: 24px;
}

.wrapper {
  width: 100%;
  height: 100%;
  margin-bottom: 26px;
}

.logo {
  width: 400px;
  height: 100px;
  margin: 0 auto;
}

.logo svg {
  width: 400px !important;
  height: 100px !important;
}
