.serialNumber {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}

.serialNumber button {
  margin-left: 6px;
}

.form :global(.ant-form-item) {
  margin-bottom: 12px;
}
.form :global(.ant-form-item-label) {
  line-height: 1.918em;
}
