.quickButton i {
  margin: 0 !important;
  /*color: rgba(0, 0, 0, 0.65);*/
}

.button {
  border: 1px solid #eeeeee !important;
  height: 60px;
  padding-left: 16px !important;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #1890ff;
  margin-bottom: 16px;
}
a.button {
  padding: 16px 0;
}
.button :global(.anticon) {
  font-size: 20px;
}
