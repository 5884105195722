.updating {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999999;
}

.updating > div {
  background-color: #fff;
  border-radius: 5px;
  padding: 64px;
  text-align: center;
}

.updating svg.logo {
  display: block;
  height: 128px;
  margin: 0 auto 48px;
  width: 128px;
}

.updating p {
  color: #0a50ad;
  font-size: 18px;
  margin: 0;
}
