.card {
  border-radius: 4px;
}

.card :global(.ant-card-body) {
  padding-top: 0;
  padding-bottom: 0;
}

.card :global(.ant-card-head) {
  border-bottom: 0;
}

.card .divider {
  margin-left: -24px;
  margin-right: -24px;
  background-color: #f0f0f0;
}

.card .item {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.form {
  margin-top: 24px;
}

.form .unbold :global(.ant-form-item-label) {
  margin-top: 12px;
  margin-bottom: 6px;
  font-weight: normal;
}

.bold :global(.ant-form-item-label) {
  font-weight: 500;
  line-height: 16px;
}

.form :global(.ant-form-item-label) {
  font-weight: 500;
  line-height: 16px;
  /*vertical-align: bottom;*/
}

.form .nonStyle :global(.ant-form-item-label) {
  font-weight: normal;
  line-height: 32px;
}

.code {
  /*display: flex;*/
  margin-bottom: 6px;
}

.code :global(.ant-form-item-children) {
  display: flex;
  width: 100%;
  margin-bottom: 4px;
}

.code input {
  margin-right: 6px;
}

.type :global(.ant-form-item-children) {
  width: 50%;
}

.type :global(.ant-radio-group) {
  display: flex;
  justify-content: space-between;
}

.type :global(.ant-radio-wrapper) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border: 1px solid #f0f0f0;
  margin-top: 6px;
  border-radius: 4px;
  padding: 12px;
}

.type :global(.ant-radio-wrapper .ant-radio) {
  display: none;
}

.type :global(.ant-radio-wrapper-checked) {
  border: 1px solid #1890ff;
  color: #1890ff;
}

.type .typeInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.type .typeInner :global(.anticon) {
  font-size: 22px;
  margin-bottom: 16px;
}

.radio :global(.ant-form-item-children) {
  width: 100%;
}

.radio :global(.ant-radio-group) {
  display: block;
}

.radio :global(.ant-radio-wrapper) {
  width: 100%;
  border: 1px solid #f0f0f0;
  margin-top: 6px;
  border-radius: 4px;
  padding: 12px;
}

.radio :global(.ant-radio-wrapper-checked) {
  border: 1px solid #1890ff;
}

.date {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding: 8px 16px;
  margin-bottom: 16px;
}

.date .heading {
  display: flex;
  justify-content: space-between;
}

.date .heading button {
  padding: 0;
}

.date:last-child {
  border-left: 4px solid #f5222d;
}
